(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("grapesjs"));
	else if(typeof define === 'function' && define.amd)
		define(["grapesjs"], factory);
	else if(typeof exports === 'object')
		exports["grapesjs-mjml"] = factory(require("grapesjs"));
	else
		root["grapesjs-mjml"] = factory(root["grapesjs"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__463__) {
return 